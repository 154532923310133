import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { fetchAdminList, delAdminer, addAdminer, updateAdminer, fetchRoleList } from '@/api/sys';
import Pagination from '@/components/Pagination';
import { validateMobile } from '@/utils/validate';
export default {
  name: 'adminList',
  components: {
    Pagination: Pagination
  },
  data: function data() {
    return {
      // 查询字段
      searchForm: {
        page: 1,
        per_page: 20,
        keyword: ''
      },
      // 表格数据
      dataList: [],
      // 总数
      totalPage: 0,
      tableLoading: false,
      btnLoading: false,
      selectCount: 0,
      selectList: [],
      userDialog: false,
      dialogForm: {
        id: '',
        name: '',
        password: '',
        mobile: '',
        realname: '',
        role_id: []
      },
      textMap: {
        update: '编辑管理员',
        create: '添加管理员'
      },
      roleOps: [],
      dialogTitle: '',
      rules: {
        name: [{
          required: true,
          message: '名称不能为空'
        }],
        password: [{
          required: true,
          message: '密码不能为空',
          trigger: 'blur'
        }],
        mobile: [{
          required: true,
          validator: validateMobile,
          trigger: 'blur'
        }],
        realname: [{
          required: true,
          message: '真实姓名不能为空',
          trigger: 'blur'
        }],
        role_id: [{
          required: true,
          message: '至少选择一个角色',
          trigger: 'blur'
        }]
      }
    };
  },
  created: function created() {
    this.getTableData();
    this.handleSelect();
  },
  methods: {
    getTableData: function getTableData() {
      var _this = this;

      this.tableLoading = true;
      var data = this.searchForm;
      console.log(data);
      fetchAdminList(data).then(function (response) {
        console.log(response);
        _this.dataList = response.data;
        _this.totalPage = response.meta.total;
        _this.tableLoading = false;
      }).catch(function (err) {
        console.log(err);
        _this.tableLoading = false;

        _this.$message({
          type: 'warning',
          message: err.data['message']
        });
      });
    },
    handleSearch: function handleSearch() {
      this.getTableData();
    },
    handlePageCurrent: function handlePageCurrent(val) {
      console.log(val);
      this.searchForm.page = val;
      this.getTableData();
    },
    handlePageSize: function handlePageSize(val) {
      console.log(val);
      this.searchForm.per_page = val;
      this.searchForm.page = 1;
    },
    handleCreate: function handleCreate() {
      var _this2 = this;

      console.log('添加管理员');
      console.log(this.dialogForm);
      this.$refs['dialogForm'].validate(function (valid) {
        if (valid) {
          var data = Object.assign({}, _this2.dialogForm);
          data['role_id'] = data['role_id'].join(',');
          addAdminer(data).then(function (res) {
            console.log(res);

            _this2.$message({
              type: 'success',
              center: true,
              message: '添加成功'
            });
          }).catch(function (err) {
            _this2.$message({
              type: 'warning',
              message: err.data['message']
            });
          });
        }
      });
    },
    handleUpdate: function handleUpdate() {
      var _this3 = this;

      console.log('修改');
      console.log(this.roleForm);
      this.$refs['dialogForm'].validate(function (valid) {
        if (valid) {
          _this3.btnLoading = true;
          var data = Object.assign({}, _this3.dialogForm);
          data['role_id'] = data['role_id'].join(',');
          updateAdminer(data).then(function (res) {
            console.log(res);

            _this3.$message({
              type: 'success',
              message: '修改成功'
            });

            _this3.userDialog = false;
            _this3.btnLoading = false;

            _this3.getTableData();
          }).catch(function (err) {
            _this3.btnLoading = false;

            _this3.$message({
              type: 'warning',
              message: err.data['message']
            });
          });
        }
      });
    },
    handledelBtn: function handledelBtn(row) {
      var _this4 = this;

      var content = "\u60A8\u786E\u8BA4\u8981\u5220\u9664".concat(row.name, "\u7528\u6237?");
      var title = '删除';
      this.$confirm(content, title, {
        confirmButtonText: '提交',
        cancelButtonText: '取消'
      }).then(function () {
        var data = {
          user_id: row.id
        };
        delAdminer(data).then(function (res) {
          console.log(res);

          _this4.$message({
            type: 'success',
            message: '删除成功'
          });

          var index = _this4.dataList.indexOf(row);

          _this4.dataList.splice(index, 1);
        }).catch(function (err) {
          _this4.$message({
            type: 'error',
            message: err
          });
        });
      }).catch(function () {});
    },
    handleEditBtn: function handleEditBtn(row) {
      var _this5 = this;

      var obj = Object.assign({}, row);
      var roles = row.roles;
      var arr = [];

      for (var i in roles) {
        arr.push(roles[i]['id']);
      }

      this.dialogForm = {
        id: obj.id,
        name: obj.name,
        password: obj.password,
        mobile: obj.mobile,
        realname: obj.realname,
        role_id: arr
      };
      this.dialogTitle = 'update';
      this.userDialog = true;
      this.$nextTick(function () {
        _this5.$refs['dialogForm'].clearValidate();
      });
    },
    resetUser: function resetUser() {
      this.dialogForm = {
        id: '',
        name: '',
        password: '',
        mobile: '',
        realname: '',
        role_id: []
      };
    },
    handleAdd: function handleAdd() {
      var _this6 = this;

      this.resetUser();
      this.userDialog = true;
      this.dialogTitle = 'create';
      this.$nextTick(function () {
        _this6.$refs['dialogForm'].clearValidate();
      });
    },
    handleSelect: function handleSelect() {
      var _this7 = this;

      fetchRoleList().then(function (res) {
        console.log(res.data);
        _this7.roleOps = res.data;
      });
    },
    handlechange: function handlechange(item) {
      console.log('change', item);
    },
    handleRemove: function handleRemove(item) {
      console.log('remove', item); // const index = this.dialogForm['role_id'].indexOf(item)
      // this.dialogForm['role_id'].splice(index, 1)
      // this.$set(this.dialogForm, )
    }
  }
};